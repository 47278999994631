@tailwind base;
@tailwind components;
@tailwind utilities;

@property --wipe-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}

body {
  overflow: auto;
  background-image: url("/public/images/background/background-image.webp");
  background-size: cover;
}

.hb {
  width: 50px;
  margin: 0 auto;
  display: block;
}

* {
  scroll-behavior: smooth;
}

.scale-up-x-y {
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: scaleupxy;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
}

.scale-up {
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: scaleup;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.scale-up-y {
  position: absolute;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: scaleupy;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  left: 50%;
  transform: translate(-50%, 0);
  width: 88%;
}

@keyframes scaleupy {
  0% {
    width: 0;
  }
  100% {
    width: 88%;
  }
}

.animate-in {
  --wipe-position: 100%;
  --gradient-length: 20%;
  mask-image: linear-gradient(
    to bottom,
    black var(--wipe-position),
    transparent calc(var(--wipe-position) + var(--gradient-length)),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    black var(--wipe-position),
    transparent calc(var(--wipe-position) + var(--gradient-length)),
    transparent
  );
  transition: --wipe-position 600ms cubic-bezier(0, 0.55, 0.45, 1);
  animation-name: spin;
  animation-duration: 5.2s;
  animation-iteration-count: 1;
}

.boxContainer {
  --b: 2px; /* thickness of the border */
  --c: rgba(255, 255, 255, 1); /* color of the border */
  --w: 50px; /* width of border */

  transition: all;
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);

  &:hover {
    --w: 250px; /* width of border */
  }
}

.inner-shadow {
  position: relative;
  clip-path: polygon(
    0 100%,
    30% 100%,
    33% 96%,
    67% 96%,
    70% 100%,
    100% 100%,
    100% 0,
    70% 0,
    67% 4%,
    33% 4%,
    30% 0,
    0 0
  );
  -moz-box-shadow: inset 0 0 5px 3px #ffffff;
  -webkit-box-shadow: inset 0 0 5px 3px#ffffff;
  box-shadow: inset 0 0 5px 3px #ffffff;
  border-image: linear-gradient(
      90deg,
      rgba(28, 93, 255, 0.1) 0%,
      rgb(27, 45, 67) 49%,
      rgba(28, 93, 255, 0.1) 100%
    )
    30;

  border-width: 31px;
  animation-name: clip-path-anim;
  animation-duration: 2.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.outer-clip-border {
  clip-path: polygon(
    0 100%,
    30% 100%,
    33% 96%,
    67% 96%,
    70% 100%,
    100% 100%,
    100% 0,
    70% 0,
    67% 4%,
    33% 4%,
    30% 0,
    0 0
  );
  -moz-box-shadow: inset 0 0 3px 2px #ffffff7d;
  -webkit-box-shadow: inset 0 0 3px 2px#ffffff7d;
  box-shadow: inset 0 0 3px 2px #ffffff7d;
  border-image: linear-gradient(
      90deg,
      rgba(196, 212, 253, 0.9) 0%,
      rgba(96, 165, 250, 1) 49%,
      rgba(195, 212, 255, 0.9) 100%
    )
    30;
  border-width: 2px;
  animation-name: clip-path-anim;
  animation-duration: 2.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes clip-path-anim {
  0% {
    clip-path: polygon(
      0 100%,
      50% 100%,
      50% 96%,
      50% 96%,
      50% 100%,
      100% 100%,
      100% 0,
      50% 0,
      50% 4%,
      50% 4%,
      50% 0,
      0 0
    );
  }
  100% {
    clip-path: polygon(
      0 100%,
      30% 100%,
      33% 96%,
      67% 96%,
      70% 100%,
      100% 100%,
      100% 0,
      70% 0,
      67% 4%,
      33% 4%,
      30% 0,
      0 0
    );
  }
}

.box {
  --b: 3px; /* thickness of the border */
  --c: rgba(255, 255, 255, 0.7); /* color of the border */
  --w: 900px; /* width of border */
  border: var(--b) #000; /* space for the border */
  --_g: #00000000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
}

swiper-container {
  max-width: 40vw;
  max-height: 70vh;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

swiper-slide {
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes reveal {
  0% {
    -webkit-mask-image: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  100% {
    -webkit-mask-image: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 1) 100%,
      rgba(0, 0, 0, 0) 125%,
      rgba(0, 0, 0, 0) 200%
    );
  }
}

.boxButtonLg {
  --b: 2px; /* thickness of the border */
  --c: rgba(255, 255, 255, 0.7); /* color of the border */
  --w: 15px; /* width of border */
  /* border-radius: 10px; */

  transition: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
  &:hover {
    --w: 550px;
  }
}

.boxButton {
  --b: 2px; /* thickness of the border */
  --c: rgb(20, 184, 166); /* color of the border */
  --w: 15px; /* width of border */
  /* border-radius: 10px; */

  transition: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
  &:hover {
    --w: 250px;
  }
}

.text-glow {
  text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
}

.boxButtonSelected {
  --b: 2px; /* thickness of the border */
  --c: rgba(255, 255, 255, 1); /* color of the border */
  --w: 250px; /* width of border */
  /* border-radius: 10px; */

  transition: all;
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
}

.background-gradient {
  background: linear-gradient(
    0deg,
    rgba(7, 7, 37, 0.01) 0%,
    rgba(149, 181, 255, 0.1) 49%,
    rgba(7, 7, 32, 0.01) 100%
  );
}

.light-bg-anim {
  @apply fixed w-full h-16 transition-all  mix-blend-lighten  duration-500 bg-blue-200/10 shadow-[0_0px_10px_rgba(0,_112,_184,_0.9)];
  filter: blur(40px);
  animation-name: translate;
  mix-blend-mode: screen;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes translate {
  0% {
    transform: translateY(-800%);
  }
  25% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(1500%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-800%);
  }
}

.background-pattern {
  opacity: 0.9;
  background-image: linear-gradient(
      rgba(68, 76, 247, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(
      to right,
      rgba(68, 76, 247, 0.05) 1px,
      rgba(229, 229, 247, 0) 1px
    );
  background-size: 20px 20px;
  background-color: gradi;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.9;
  }
}

.small-text {
  font-size: 35px;
  font-weight: 100;
}

@keyframes glitch-anim-second {
  0% {
    clip: rect(57px, 9999px, 56px, 0);
  }
  5% {
    clip: rect(54px, 9999px, 88px, 0);
  }
  10% {
    clip: rect(40px, 9999px, 46px, 0);
  }
  15.0% {
    clip: rect(62px, 9999px, 60px, 0);
  }
  20% {
    clip: rect(39px, 9999px, 67px, 0);
  }
  25% {
    clip: rect(35px, 9999px, 37px, 0);
  }
  30.0% {
    clip: rect(2px, 9999px, 39px, 0);
  }
  35% {
    clip: rect(34px, 9999px, 56px, 0);
  }
  40% {
    clip: rect(22px, 9999px, 86px, 0);
  }
  45% {
    clip: rect(37px, 9999px, 28px, 0);
  }
  50% {
    clip: rect(75px, 9999px, 23px, 0);
  }
  55.0% {
    clip: rect(51px, 9999px, 93px, 0);
  }
  60.0% {
    clip: rect(60px, 9999px, 82px, 0);
  }
  65% {
    clip: rect(5px, 9999px, 42px, 0);
  }
  70% {
    clip: rect(91px, 9999px, 53px, 0);
  }
  75% {
    clip: rect(89px, 9999px, 77px, 0);
  }
  80% {
    clip: rect(50px, 9999px, 84px, 0);
  }
  85.0% {
    clip: rect(9px, 9999px, 26px, 0);
  }
  90% {
    clip: rect(61px, 9999px, 48px, 0);
  }
  95% {
    clip: rect(36px, 9999px, 6px, 0);
  }
  100% {
    clip: rect(43px, 9999px, 2px, 0);
  }
}

@keyframes glitch-anim-first {
  0% {
    clip: rect(50px, 9999px, 21px, 0);
  }
  5% {
    clip: rect(17px, 9999px, 52px, 0);
  }
  10% {
    clip: rect(25px, 9999px, 4px, 0);
  }
  15.0% {
    clip: rect(11px, 9999px, 47px, 0);
  }
  20% {
    clip: rect(75px, 9999px, 16px, 0);
  }
  25% {
    clip: rect(63px, 9999px, 88px, 0);
  }
  30.0% {
    clip: rect(71px, 9999px, 17px, 0);
  }
  35% {
    clip: rect(73px, 9999px, 99px, 0);
  }
  40% {
    clip: rect(98px, 9999px, 61px, 0);
  }
  45% {
    clip: rect(75px, 9999px, 59px, 0);
  }
  50% {
    clip: rect(32px, 9999px, 91px, 0);
  }
  55.0% {
    clip: rect(98px, 9999px, 78px, 0);
  }
  60.0% {
    clip: rect(11px, 9999px, 32px, 0);
  }
  65% {
    clip: rect(18px, 9999px, 36px, 0);
  }
  70% {
    clip: rect(72px, 9999px, 46px, 0);
  }
  75% {
    clip: rect(81px, 9999px, 26px, 0);
  }
  80% {
    clip: rect(2px, 9999px, 49px, 0);
  }
  85.0% {
    clip: rect(72px, 9999px, 47px, 0);
  }
  90% {
    clip: rect(63px, 9999px, 24px, 0);
  }
  95% {
    clip: rect(44px, 9999px, 7px, 0);
  }
  100% {
    clip: rect(89px, 9999px, 65px, 0);
  }
}

@keyframes spin {
  0% {
    --wipe-position: calc(-1 * var(--gradient-length));
  }
  100% {
    --wipe-position: calc(10 * var(--gradient-length));
  }
}

@keyframes scaleup {
  0% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes scaleupxy {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #d1d7fd;
}

::-webkit-scrollbar-track:hover {
  background-color: #c2c2c2;
}

::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #8d9cd9;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background-color: #636ea3;
}

.spin-up {
  animation: spin2 1s linear infinite;
}

@keyframes spin2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swal2-popup {
  --b: 3px; /* thickness of the border */
  --c: rgba(255, 255, 255, 0.7); /* color of the border */
  --w: 30px; /* width of border */
  border: var(--b) #000 !important; /* space for the border */
  --_g: #00000000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p) !important;
  clip-path: polygon(
    0 100%,
    30% 100%,
    33% 96%,
    67% 96%,
    70% 100%,
    100% 100%,
    100% 0,
    70% 0,
    67% 4%,
    33% 4%,
    30% 0,
    0 0
  ) !important;
  -moz-box-shadow: inset 0 0 3px 2px #ffffff7d !important;
  -webkit-box-shadow: inset 0 0 3px 2px#ffffff7d !important;
  box-shadow: inset 0 0 3px 2px #ffffff7d !important;
  border-image: linear-gradient(
      90deg,
      rgba(28, 93, 255, 0.2) 0%,
      rgba(96, 165, 250, 1) 49%,
      rgba(28, 93, 255, 0.2) 100%
    )
    30 !important;
  border-width: 0px !important;
}

.card {
  transition-duration: 300ms;
  transition-property: transform;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
}

.card:hover {
  transition-duration: 150ms;
}

.card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  max-height: 85vh;
  mix-blend-mode: screen;
}

.app {
  perspective: 1500px;
}
